import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { regular11, regular12, regular16, regular9 } from "./typography"

const SubmitPasswordForm = styled.form`
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-bottom: 0;
    max-width: 400px;
    margin: 0 auto;
  }
  p{
    margin-bottom: 0.6rem;
  }
   input {
    margin: 0 1rem 0 0;
    padding: 1.2rem 1.6rem;
    width: 70%;
   }

   button {
    @media screen and (min-width: ${breakpoints.md}px) {
    margin: 1.6rem auto;
    }
   }
`

export {
    SubmitPasswordForm
}
